import { BlogCollection } from '~/enums'

type Props = {
  collection: BlogCollection
}

/**
 * Hardocded paths for the blog landing pages.
 *
 * @param Props
 * @returns string
 */
export default function useBlogPath({ collection }: Props): string {
  if (collection === BlogCollection.STORIES) return '/blog/stories'
  if (collection === BlogCollection.NEWS) return '/blog/news'
  if (collection === BlogCollection.PRESS_RELEASES) return '/blog/press-releases'
  if (collection === BlogCollection.TECH) return '/blog/tech'
  return '/'
}
