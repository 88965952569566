<template>
  <button class="flex items-center py-3 pl-3 pr-5 space-x-2 text-lg font-medium duration-150 rounded-full hover:bg-gray-100/10"
          @click="goToPreviousPage"
  >
    <IconArrowRight class="w-6 h-6 rotate-180" /><span class="md:inline"
                                                       :class="{ hidden: !showLabelOnMobile }"
    >
      Back
    </span>
  </button>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import useBlogPath from '../../composables/blog-path'
import { navigateTo } from '#app'
import type { BlogCollection } from '~/enums'

type BackLinkProps = {
  showLabelOnMobile?: boolean
  collection: BlogCollection
}

const props = withDefaults(defineProps<BackLinkProps>(), {
  showLabelOnMobile: false
})
const router = useRouter()
const blogPath = useBlogPath({ collection: props.collection })

function goToPreviousPage() {
  // if previous route exists in router state go to it
  // if previous document referrer exists and is on the same domain, go to it
  // otherwise go to landing page
  if (router.options.history.state.back) {
    return router.go(-1)
  }
  else if (document.referrer && document.referrer.includes(window.location.origin)) {
    return navigateTo(document.referrer, { external: true })
  }
  else {
    return navigateTo(blogPath, { external: true })
  }
}
</script>
